
import dayjs from 'dayjs'
import { _analytics } from '@/utilities/analytics'
import datalayers from '@/utilities/datalayers.js'
import { OUXFormLayout } from '@ocean-ux/library/src/components'
import { getCookie } from '@ocean-ux/library/src/shared/utilities/cookie-handling'
import LoadingModule from '@/components/UI/loading-module.vue'
import axios  from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'form-holder',
  /* eslint-disable @typescript-eslint/no-explicit-any */
  data (): any {
    return {
      isSubmitting: false,
      percentageLoaded: 0
    }
  },
  components: {
    'oux-form-layout': OUXFormLayout,
    'loading-module': LoadingModule
  },
  async mounted (): Promise<void> {
    // set the formUrl in the store
    this.$store.commit('setFormData', {
      name: 'formUrl',
      value: new URL(window.location.href)
    })

    // get loan_amount and loan_term from URL parametors
    // e.g. /example-url/?loan_amount=2200&loan_term=24
    this.getLoanDetails()


    // if NOT on root url ("/")
    // which is the same as IS on the form
    // initialise analytics step funnel and time on step tracking
    // and set the next route
    if (this.$route.name !== "/") {
      datalayers.formStart();
      this.createVirtualPageview(this.$route.path)

      // initialise analytics on mount
      _analytics.time_on_step(this.$route.path, this.$route.path)
    }

    // get google tracking cookies
    const gtmSID = getCookie('gtmSID')
    const trackingCookies = [
      { name: 'gClid', value: getCookie('GClid') },
      { name: 'gtmSID', value: gtmSID },
    ]
    // save cookie values to formData vuex store
    trackingCookies.forEach((cookie) => {
      this.$store.commit('setFormData', cookie)
    })

    if (!gtmSID || gtmSID === null || gtmSID === '' || gtmSID === 'null') {
      setTimeout(() => {
        const gtmDelayedObject = { name: 'gtmSID', value: sessionStorage.getItem('gtmSID') }
        this.$store.commit('setFormData', gtmDelayedObject)
      }, 5000)
    }

    // Ocean Navigation Position AB Test #60800
    if (process.env.VUE_APP_BRAND === 'ocean') {
      //this.handleABTest('navigationPosition')
    }
  },
  watch: {
    // watch for $route to change
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    '$route': function (router): void {
      // if on the path "/" (index) dont try to set routes
      // app will automatically send user to first form step
      // and after this set routes
      if (router.name !== "/") {
        // set animation direction and router-view :key property var
        const routeDirection = router.query.d

        // send analytics
        if (routeDirection === 'f') {
          _analytics.time_on_step(this.prevRoute, router.path)
        }
        this.createVirtualPageview(this.$route.path)
      }
    }
  },
  computed: {
    // get the prevRoute variable from vuex store
    // used for analytics (step time)
    prevRoute: function (): string {
      return this.$store.getters.prevRoute
    },
    hideProgressBar: function(): boolean {
      return this.$store.getters.hideProgressBar || this.$route.path.includes('apply/review-details')
    },
    hideNavigation: function(): boolean {
      return this.$store.getters.hideNavigation
    },
    formData: function(): any {
      return this.$store.getters.formData
    }
  },
  methods: {
    getLoanDetails: function (): void {
      // get loan amount from router params (query) or default to 6500
      const loanAmount = {
        name: 'loanAmount',
        value: Number(this.$route.query.loan_amount) || 6500
      }
      // get loan term from router params (query) or default to 5 (years)
      const loanTerm = {
        name: 'loanTerm',
        value: Number(this.$route.query.loan_term) || 5
      }

      // set the loan amount in store
      this.$store.commit('setFormData', loanAmount)
      // set the loan term in store
      this.$store.commit('setFormData', loanTerm)
    },
    createVirtualPageview: function (path: string): void {
      // virtual_pageview() method is imported from @/utilities/analytics
      // this builds the VP object to be sent to GTM
      const pageObject = _analytics.virtual_pageview(path)
      if (pageObject !== undefined) {
        datalayers.virtual_pageview(pageObject)
      }
    },
    submitForm: function(): void {
      const submitStartTime = dayjs()
      window.scrollTo(0, 0);
      datalayers.formSubmit();

      const baseURL = window.location.origin
      const submitUrl = `${baseURL}/api/applicaitons/create`;

      const time = 40000;
      const onePercentage = time / 100;

      this.delay(onePercentage, 0, time);

      const formData = this.formData;
      this.isSubmitting = true
      formData.gtmSID = sessionStorage.getItem('gtmSID') || getCookie('gtmSID') || formData.gtmSID
      formData.sourceId = getCookie('sourceIdCFJourney') || 4497; // Default to CF Organic if no source for CF in cookie
      formData.sourceText = getCookie('CFJourney') || 'cforganic_';
      console.log('Form Data',formData)

      axios.post(submitUrl, formData)
        /* eslint-disable @typescript-eslint/no-explicit-any */
        .then((response: any) => {

          this.$store.commit("setResults", response.data);

          if (response.data.hardstopStatus === 0) {
            _analytics.time_to_submit('accepted', submitStartTime)
            datalayers.rnScoreFire(response.data.rnScore);
            datalayers.formAccept()

            this.optimizeTrigger("accepted")

            this.$router.push({
              path: "/results/form-results/",
              query: { d: "f" },
            });
          } else {
            _analytics.time_to_submit('declined', submitStartTime)
            datalayers.rnScoreFire(response.data.rnScore, response.data.reason);
            datalayers.formDecline();

            this.optimizeTrigger("decline")

            this.$router.push({
              path: "/results/decline",
              query: { d: "f" },
            });
          }
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          console.log(error)
        this.isSubmitting = false
          this.emitter.emit('submit-error','error');
        });
    },
    optimizeTrigger: function (eventName: string): void {
      datalayers.optimize_trigger(eventName)
    },
    delay: function(timeSpan: number, counter: number, finish: number): void {
      const self = this;
      if (timeSpan * counter >= finish) {
        return;
      }
      setTimeout(function() {
        self.percentageLoaded = (timeSpan / finish) * counter;
        if (self.percentageLoaded === 1) {
          self.percentageLoaded = 0.99;

        } else {
          self.delay(timeSpan, counter + 1, finish);
        }
      }, timeSpan);
    }
  }
})
