
import { defineComponent } from 'vue'

export default defineComponent({
  name: "LoadingImage",
  props: {
    color: {
      type: String,
      default: "white"
    }
  }
})
