
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'loading-water',
  data (): any {
    return {
      wave_ready: false,
      svgSize: 140,
      lightBlue: '#88DEFF',
      mainBlue: '#29abe1'
    }
  },
  props: {
    ready: Boolean,
    percentageLoaded: Number
  },
  watch: {
    'ready': function (_new): void {
      console.log('app result ready', _new)
    }
  }
})
