
import LoadingAnimation from "./loading-animation.vue";
import { defineComponent } from 'vue'

export default defineComponent({
  name: "loading-module",
  components: {
    "loading-animation": LoadingAnimation
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    percentageLoaded: {
      type: Number,
      default: 0
    }
  }
})
