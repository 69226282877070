/* eslint-disable */
const carFinace = 'car finance';
const getCookie = (name) => {
  const value = "; " + document.cookie
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(";").shift()
  }
  return '';
}

const waitForGlobal = (key, callback) => {
  if (window[key]) {
      callback()
  } else {
      setTimeout(function () {
          waitForGlobal(key, callback);
      }, 100)
  }
}

function setOneTrustConsents(detail) {
  let cookieDomain

  if (window.origin.includes('oceanfinance.co.uk')) {
    cookieDomain = 'oceanfinance.co.uk'
  } else if (window.origin.includes('iltest.co.uk')) {
    cookieDomain = 'iltest.co.uk'
  }

  const cookieDomainParam = cookieDomain ? `domain=${cookieDomain}` : ''
  // get time
  const now = new Date()
  // add 1 hour to 'now'
  now.setTime(now.getTime() + 1 * 3600 * 1000)
  document.cookie = `oneTrustConsents=${JSON.stringify(detail)};expires=${now};${cookieDomainParam}`
}

const datalayers = {
  fireQueuedDatalayers: function () {
    // when consent changes
    // loop through any queued DL and fire
    waitForGlobal('OneTrust', function () {
      window.OneTrust.OnConsentChanged(({detail}) => {
        // save consents to localStorage and cookie
        setOneTrustConsents(detail)
        const queuedDL = JSON.parse(sessionStorage.getItem('oceanQueuedDL'))
        if (queuedDL.length) {
          console.log('FIRE DL FROM BEFORE CONSENT')
          queuedDL.forEach((datalayer) => {
            this.sendDatalayer(datalayer)
          })
          sessionStorage.clear('oceanQueuedDL')
        }
      })
    })
  },
  // used to send form funnel on section submission
  datalayerFunnel: function (product, status, commission) {
    const digiCommission = commission ? commission : undefined
    // build datalayer object with product name and status
    // status e.g. 'started', 'submitted', 'accepted', 'accepted and proceeded' etc
    const object = {
      'event': 'product',
      'product_name': product,
      'product_status': status,
      'product_journey': undefined,
      'product_commission': digiCommission,
      'product_question': undefined,
      'product_validation': undefined,
      'product_eligibility': undefined,
      'user_title': undefined,
      'user_residential': undefined,
      'user_employment': undefined,
      'user_age': undefined,
      'user_marriage': undefined
    }
    // send and log the datalayer
    this.sendDatalayer(object, 'funnel:')
  },

  // used to send datalayer on question validation
  datalayerValidation: function (product, question, valid, value) {
    const validStatus = valid === true ? 'valid' : 'invalid'

    // build datalayer object with product name, question text and if validation passed
    const object = {
      'event': 'product',
      'product_name': product,
      'product_status': undefined,
      'product_journey': undefined,
      'product_commission': undefined,
      'product_question': question,
      'product_validation': validStatus,
      'product_eligibility': undefined,
      'user_title': undefined,
      'user_residential': undefined,
      'user_employment': undefined,
      'user_age': undefined,
      'user_marriage': undefined
    }

    // if valid check to see if there is additional data we want to send
    // e.g. user_title, user_age, user_residential, user_employment
    if (valid) {
      const customData = this.customUserData(question, value)
      // if customData is defined, check both key and value populated
      // and set new datalayer object property using the key
      // and provide the value
      if (customData !== undefined) {
        if (customData.key && customData.value) {
          object[customData.key] = customData.value
        }
      }
    }

    // send and log the datalayer
    this.sendDatalayer(object, 'validation:')
  },

  customUserData: function (question, value) {
    switch (question) {
      case 'title':
        return {
          key: 'user_title',
          value: value
        }
      case 'dateOfBirth':
        return {
          key: 'user_age',
          value: this.calculateAge(value)
        }
      case 'maritalStatus':
        return {
          key: 'user_marriage',
          value: value
        }
      case 'employmentStatus':
        return {
          key: 'user_employment',
          value: value
        }
      case 'residentialStatus':
        return {
          key: 'user_residential',
          value: value
        }
      case 'monthlyIncome':
        return {
          key: 'user_income_monthly',
          value: value
        }
      default:
        return undefined
    }
  },

  virtual_pageview: function (dataset) {
    const pageView = {
      'event': 'Virtual Pageview', // static
      'vpUrl': dataset.fragment, // dynamically append URL fragment as listed below. /vp/ part flags URL as virtual pageview in GA
      'vpTitle': 'VP - ' + dataset.title // dynamically append title as listed below. 'VP - '  flags page title as virtual pageview in GA
    }
    // send and log the datalayer
    this.sendDatalayer(pageView, 'virtual_pageview:')
  },

  sendHelpTextDatalayer: function (fieldname) {
    this.sendDatalayer({
      'event': 'gtmCustomEvent',
      'ec': 'CF Form Event',
      'ea': 'Help Text Link Click',
      'el': fieldname
    }, 'help-text')
  },

  // used to send form funnel on section submission
  formStart: function () {
    this.datalayerFunnel(carFinace, 'started')
  },

  formSubmit: function () {
    this.datalayerFunnel(carFinace, 'submitted')
  },

  formDecline: function () {
    this.datalayerFunnel(carFinace, 'declined')
  },

  formAccept: function () {
    this.datalayerFunnel(carFinace, 'accepted')
  },

  formAcceptCTAClick: function () {
    this.datalayerFunnel(carFinace, 'accepted-cta-clicked')
  },

  rnScoreFire: function (value, reason) {
    const object = {
      'event': 'gtmCustomEvent',
      'ec': 'Car Finance Form Event',
      'ea': 'RN Score',
      'el':  value,
    }
    if (reason) {
      object['cf_hardstop'] = reason;
    }

    this.sendDatalayer(object, 'Applicant RNScore')
  },

  // send and log the datalayer function
  sendDatalayer: function (object, eventName) { //
    // check if user has already consented
    const consented = getCookie('OptanonAlertBoxClosed')
    // if consent fire DL
    if (consented) {
      // log the datalayer object
      const uatIltest = window.location.href.indexOf('iltest') > 0
      const uatTmtest = window.location.href.indexOf('tmtest') > 0
      const uatLocal = window.location.href.indexOf('localhost') > 0
      if (uatIltest || uatTmtest || uatLocal) {
        // eslint-disable-next-line
        console.log(eventName, object)
        if (uatLocal) {
          return
        }
      }

      // send datalayer object to GTM
      try {
        // eslint-disable-next-line
        dataLayer.push(object)
      } catch (err) {
        // eslint-disable-next-line
      }
    } else {
      // if not consented queue up the dl object
      console.log('NOT CONSENTED YET')
      let queuedDatalayer = [object]
      const sessionDatalayers = JSON.parse(sessionStorage.getItem('oceanQueuedDL'))
      if (sessionDatalayers) {
        queuedDatalayer = [...queuedDatalayer, ...sessionDatalayers]
      }
      sessionStorage.setItem('oceanQueuedDL', JSON.stringify(queuedDatalayer))
      console.log('UPDATED DL QUEUE', JSON.parse(sessionStorage.getItem('oceanQueuedDL')))
    }
  },

  calculateAge (dateString) {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },

  optimize_trigger: function(eventName) {
    const optimiseEventType = `optimize.activate.${eventName}`
    console.log('optimiseTrigger', { event: optimiseEventType })
    try {
      // eslint-disable-next-line
      dataLayer.push({ event: optimiseEventType })
    } catch (er) {
      console.log(er)
    }
  }
}

export default datalayers
