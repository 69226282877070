export const setNestedProperty = <T>(
    object: { [key: string]: any }, // eslint-disable-line
    path: string,
    value: any, // eslint-disable-line
    index: number|undefined = undefined,
  ): T => {
    const paths = path.split(".")
    const newObject = { ...object }
  
    let referenceObject = newObject
  
    while (paths.length - 1) {
      const property = paths.shift()
  
      if (!property) {
        break
      }
  
      if (!(property in referenceObject)) {
        referenceObject[property] = {}
      }
  
      referenceObject = referenceObject[property]
    }
  
    // if not nested array item
    if (typeof index === 'undefined') {
      referenceObject[paths[0]] = value
    } else {
      referenceObject[paths[0]][index] = value
    }
  
    return (newObject as T)
  }
  