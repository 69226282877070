
import OUXRangeSlider from '@ocean-ux/library/src/components/inputs/range-slider-input/range-slider.vue'
import datalayers from '@/utilities/datalayers.js'
import { toCurrency } from '@ocean-ux/library/src/shared/utilities/formatters'
import { defineComponent } from 'vue'
const CFVariables = require('@/data/globalVariables/CF-globals.json')

export default defineComponent({
  name: "loan-details-setter",
  computed: {
    loanAmount: function(): number {
      return this.$store.getters.formData.loanAmount
    },
    loanTerm: function(): number {
      return this.$store.getters.formData.loanTerm
    },
    getLoanTerm: function (): number {
      return this.term ? this.term : this.loanTerm
    },
    getLoanAmount: function (): number {
      return this.amount ? this.amount : this.loanAmount
    },
  },
  data(): any {
    return {
      newLoanAmount: 0,
      newLoanTerm: 0,
      CFVariables
    };
  },
  mounted (): void {
    this.editLoanDL()
  },
  props: {
    ready: {},
    amount: {
      type: Number,
      default: null
    },
    term: {
      type: Number,
      default: null
    }
  },
  components: {
    'range-slider-input': OUXRangeSlider,
  },
  methods: {
    getValueTrimed: function(value: number): number {
      return value > 6 && value < 7 ? 6 : value
    },
    close: function(): void {
      this.$emit("closed")
    },
    overlayClick: function(): void {
      this.$emit("closed")
    },
    modalClick: function(e: any): void {
      e.stopPropagation()
    },
    setLoanTerm: function(value: number): void {
      this.newLoanTerm = value
    },
    setLoanAmount: function(data: any): void {
      this.newLoanAmount = data.value
    },
    confirm: function(): void {
      const lAmount = this.newLoanAmount
      const lTerm = this.newLoanTerm

      this.setLoanDetails(
        lAmount || this.loanAmount,
        lTerm || this.loanTerm
      );
      this.$emit("closed")
    },
    formatCurrency: function (value: string): string {
      return value ? toCurrency(value) : ''
    },
    setLoanDetails: function(loanAmount: number, loanTerm: number): void {
      datalayers.sendDatalayer({
        'event': 'gtmCustomEvent',
        'ec': 'CF Form Event',
        'ea': 'Change Amount - Updated',
        'el': `${this.formatCurrency(this.loanAmount)} - ${this.loanTerm} years | ${this.formatCurrency(loanAmount)} - ${loanTerm} years`
      }, 'set-loan-amount')

      const loanAmountTemp = {
        name: "loanAmount",
        value: Number(loanAmount)
      }
      const loanTermTemp = {
        name: "loanTerm",
        value: Number(loanTerm),
      };
      this.$store.commit("setFormData", loanAmountTemp);
      this.$store.commit("setFormData", loanTermTemp);
    },
    editLoanDL: function () {
      datalayers.sendDatalayer({
        'event': 'gtmCustomEvent',
        'ec': 'CF Form Event',
        'ea': 'Change Amount - Window Opened',
        'el': `${this.formatCurrency(this.loanAmount)} - ${this.loanTerm} months`
      }, 'change-loan-amount')
    }
  }
})
