import { createStore } from 'vuex'
import { Applicant, AddressState, Address, Employment } from '@/typescript/applicant'
import { setNestedProperty } from '@/typescript/object';

const addressModel: Address = { postcode: '', houseNumber: '', addressLevel: 0 }
const employmentModel: Employment = {
  employmentLevel: 0,
  employmentStatus: '',
  yearsAtEmployment: 0,
  employmentTitle: '',
  yearsDecimal: 0,
  monthsAtEmployment: 0,
  doesStudentWork: '',
  monthlyIncome: undefined,
  employerTown: '',
  employerName: '',
  timeAtEmployment: {
    date: '',
    months: '',
    years: ''
  }
}

// variable used to prepopulate formData state
let formDataPrepop: Applicant = {
  loanAmount: undefined,
  loanTerm: undefined,
  residentialStatus: undefined,
  title: undefined,
  firstName: undefined,
  lastName: undefined,
  dateOfBirth: undefined,
  email: undefined,
  phoneNumber: undefined,
  employmentStatus: undefined,
  acceptNewsletter: true,
  houseNameOrNumber: undefined,
  postcode: undefined,
  addresses: [addressModel],
  employments: [employmentModel],
  employerName: undefined,
  employerTown: undefined,
  employmentTitle: undefined,
  maritalStatus: undefined,
  monthlyIncome: undefined,
  formUrl: undefined
}

// update formData model with blank model and any data stored in sessionStorage
const formData = sessionStorage.getItem('formData')
if (formData !== null) {
  formDataPrepop = {
    ...formDataPrepop,
    ...JSON.parse(formData)
  }
}
/* eslint-enable */


export default createStore({
  state: {
    usingSessionstorage: false,
    isDebugging: false,
    isPrepop: false,

    // startRoute is navigated to when the app is loaded
    startRoute: '/apply/title',

    ABTests: {},

    // the next route path
    // (used by buttons for router navigation)
    nextRoute: null,
    // the previous route path
    // (used by buttons for router navigation)
    prevRoute: null,

    // stores application progress
    progress: 0,

    // form data
    // contains all data entered in the form
    // passed formDataPrepop variable is set above
    formData: formDataPrepop,

    // address history state object
    addressState: {
      yearsRequired: 3,
      yearsCollected: [],
      activeIndex: 0
    } as AddressState,

    employmentState: {
      yearsRequired: 1,
      yearsCollected: [],
      maxCount: 6,
      activeIndex: 0,
    } as AddressState,
    loanOption: {},


    reviewDetails: {},

    results: {

    },
    prepopFirstName: null,
    quoteApplyInfo: null,

    //////////////////////////////////////////////////////

    // step valid
    // used to allow/block next button navigation
    stepValid: false,
    isValidating: [] as Array<string>,
    hideProgressBar: false,
    hideNavigation: false
  },
  getters: {
    // setup getters to make state data
    // accessible within components
    // (called via computed methods)
    formData: (state) => state.formData,
    addressState: (state) => state.addressState,
    employmentState: (state) => state.employmentState,

    nextRoute: (state) => state.nextRoute,
    prevRoute: (state) => state.prevRoute,
    stepValid: (state) => state.stepValid,
    isPrepop: (state) => state.isPrepop,

    progress: (state) => state.progress,
    isDebugging: (state) => state.isDebugging,
    usingSessionstorage: (state) => state.usingSessionstorage,

    results: (state) => state.results,

    loanOption: (state) => state.loanOption,
    reviewDetails: (state) => state.reviewDetails,
    prepopFirstName: (state) => state.prepopFirstName,
    quoteApplyInfo: (state) => state.quoteApplyInfo,

    ///////////////////////////////////////////////////////
    isValidating: state => state.isValidating,
    hideProgressBar: state => state.hideProgressBar,
    hideNavigation: state => state.hideNavigation,
    ABTests: state => state.ABTests
  },
  mutations: {
    // set isDebugging state
    setIsDebugging(state, value: boolean) {
      state.isDebugging = value
    },
    setUsingSessionstorage(state, value: boolean) {
      state.usingSessionstorage = value
    },

    setPrepopState(state){
      state.isPrepop = true;
    },

    setPrepopFirstName(state, name){
      state.prepopFirstName = name;
    },
    setQuoteApplyInfo(state, model){
      state.quoteApplyInfo = model;
    },
    // set nextRoute and prevRoute
    setRoutes(state, routes) {
      // set nextRoute
      if (routes.next || routes.next === false || routes.next === undefined) {
        state.nextRoute = routes.next;
      }

      // set prevRoute
      if (routes.prev || routes.prev === false) {
        state.prevRoute = routes.prev;
      }
    },
    // set step valid state
    setStepValid(state, valid) {
      state.stepValid = valid;
    },
    // set progress amount
    setProgress(state, progress) {
      state.progress = progress;
    },
    // set the formData state
    setFormData(state, data) {
      // create tempory formData with the updated value
      const tempFormData = setNestedProperty<Applicant>(state.formData, data.name, data.value, data.index)
      // update state with tempory form_data
      state.formData = tempFormData
      // set sessionStorage
      if (state.usingSessionstorage) {
        sessionStorage.setItem('formData', JSON.stringify(state.formData))
      }
    },
    // set the formData state
    setFormDataArray(state, data) {
      try {
        const testing = setNestedProperty<Applicant>(state.formData, data.name, data.value, data.index)
        console.log('formdata', testing)
        state.formData[data.name][data.index] = data.value
      } catch (e) {
        // if the formData property is not defined
        if (data.name && typeof state.formData[data.name] === 'undefined') {
          // create new property with array type and pass the value
          state.formData[data.name] = [data.value]
        }
      }
      // set sessionStorage
      if (state.usingSessionstorage) {
        sessionStorage.setItem("formData", JSON.stringify(state.formData));
      }
    },
    // set the formData state
    prepopFormData(state, viewmodel) {
      state.formData = viewmodel;
    },
    // set address index
    updateAddressState(state, address) {
      // create tempory form_data with the updated value
      const tempAddressState = { ...state.addressState, [address.name]: address.value }
      // update state with tempory address state
      state.addressState = tempAddressState
    },
    updateAddressStateYearsCollected(state, address) {
      // create tempory form_data with the updated value
      const tempYearsCollected = [ ...state.addressState.yearsCollected ]
      // update relevant years collected value
      tempYearsCollected[address.index] = address.value
      // update state with tempory address state
      state.addressState.yearsCollected = tempYearsCollected
    },
    updateEmploymentState(state, employment) {
      // create tempory form_data with the updated value
      const tempemploymentState = { ...state.employmentState, [employment.name]: employment.value }
      // update state with tempory address state
      state.employmentState = tempemploymentState
    },
    updateEmploymentStateYearsCollected(state, employment) {
      // create tempory form_data with the updated value
      const tempYearsCollected = [ ...state.employmentState.yearsCollected ]
      // update relevant years collected value
      tempYearsCollected[employment.index] = employment.value
      // update state with tempory address state
      state.employmentState.yearsCollected = tempYearsCollected
    },
    setResults(state, results) {
      state.results = results;
    },
    setLoanOption(state, loanOption) {
      state.loanOption = loanOption;
    },
    setWholeFormData(state, data) {
      state.formData = data;
    },
    setReviewDetails(state, data) {
      state.reviewDetails = data;
    },
    /////////////////////////////////////////////////////////////////////////////////
    setIsValidating (state, validationState: { isActive: boolean, name: string }) {
      if (validationState.isActive) {
        // add input to `isValidating` list
        state.isValidating.push(validationState.name)
      } else {
        // create copy of array
        const isValidatingList = [...state.isValidating];
        // filter out the validated (finished) input
        const updatedArray = isValidatingList.filter((item: string|null) => item !== validationState.name)
        // update vuex with cleaned array
        state.isValidating = updatedArray
      }
    },
    setHideProgressBar (state, value){
      state.hideProgressBar = value;
    },
    setHideNavigation (state, value){
      state.hideNavigation = value;
    },
    setABTests (state, ABTests) {
      state.ABTests = ABTests
    }
  },

  actions: {
    prepopFormData({ commit }, viewmodel) {
      commit("prepopFormData", viewmodel);
    },
  }
})
