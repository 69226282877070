import dayjs, { Dayjs } from 'dayjs'

type VirtualPageview = {
  title: string;
  fragment: string;
}

type gaData = {
  hitType: string,
  timingCategory: string,
  timingVar: string,
  timingValue: number
}

/* eslint-disable @typescript-eslint/no-explicit-any */
declare let ga: (string: any, gaData: gaData) => void;
export const _analytics = {
    last_step_funnel: function (path: string): void {
        const path_not_set = sessionStorage.getItem(`funnel:${path}`) === null
        if (path_not_set) {
            sessionStorage.setItem(`funnel:${path}`, 'true')
            console.log(`analytics:last_step_funnel: ${path}`)
            try {
                // send to GA
            } catch (e) {
                // catch error
            }
        }
    },
    time_on_step: function (previous: string, active: string): void {
        console.log(`time_on_step: previous:${previous} active:${active}`)
        const active_time_not_set = sessionStorage.getItem(`time-start:${active}`) === null

        if (active_time_not_set) {
            let prevStart = sessionStorage.getItem(`time-start:${previous}`)
            const activeStart = dayjs().format()

            sessionStorage.setItem(`time-start:${active}`, activeStart)

            if (prevStart !== null) {
              prevStart = dayjs(prevStart).format()
              const prevStepTime = dayjs().diff(prevStart, 'second', true);

              console.log(`analytics:time_on_step: ${previous}:`)
              console.log(prevStepTime.toFixed(1))
              try {
                  // send to GA
              } catch (e) {
                  // catch error
              }
            }
        }
    },
    time_to_submit: function (result: string, start: Dayjs): void {
      const difference = dayjs().diff(start, 'millisecond', true);

      const uatIltest = window.location.href.indexOf('iltest') > 0
      const uatTmtest = window.location.href.indexOf('tmtest') > 0
      const uatLocal = window.location.href.indexOf('localhost') > 0
      if (uatIltest || uatTmtest || uatLocal) {
        // eslint-disable-next-line
        console.log(`submit finished: ${result}`, difference)
        if (uatLocal) {
          return
        }
      }

      try {
        // send to GA
        // eslint-disable-next-line
        ga('gtm3.send', {
          hitType: 'timing',
          timingCategory: 'CF Form Result Load',
          timingVar: result,
          timingValue: difference
        });
      } catch (e) {
        // catch error
      }
    },
    virtual_pageview: function (path: string): VirtualPageview|undefined {
        let page_object: VirtualPageview|undefined
        const stippedPath = path.replace(/\/\s*$/, "").replace("/apply", "");
        switch (stippedPath) {
          case '/title':
            page_object = { title: 'Title', fragment: 'input-cf-title' }
            break
          case '/name':
            page_object = { title: 'Name', fragment: 'input-cf-name' }
            break
          case '/date-of-birth':
            page_object = { title: 'DOB', fragment: 'input-cf-dob' }
            break
          case '/email':
            page_object = { title: 'Email', fragment: 'input-cf-email' }
            break
          case '/phone':
            page_object = { title: 'Phone Number', fragment: 'input-cf-phone-number' }
            break
          case '/communication':
            page_object = { title: 'Marketing Consent', fragment: 'input-cf-marketing-consent' }
            break
          case '/licence-possesion':
            page_object = { title: 'UK Drivers Licence', fragment: 'input-cf-uk-licence' }
            break
          case '/licence-type':
            page_object = { title: 'Licence Type', fragment: 'input-cf-licence-type' }
            break
          case '/vehicle-type':
            page_object = { title: 'Vehicle Type', fragment: 'input-cf-vehicle-type' }
            break
          case '/car-insurance':
            page_object = { title: 'Insurance Renewal', fragment: 'input-cf-insurance-renewal' }
            break
          case '/address-search/0':
            page_object = { title: 'Address', fragment: 'input-cf-address-search' }
            break
          case '/address-search-results/0':
            page_object = { title: 'Address Results', fragment: 'input-cf-address-results' }
            break
          case '/address-residential-status/0':
            page_object = { title: 'Address Residential Status', fragment: 'input-cf-residential-status' }
            break
          case '/address-duration/0':
            page_object = { title: 'Years at address', fragment: 'input-cf-years-at-address' }
            break
          case '/marital-status':
            page_object = { title: 'Marital Status', fragment: 'input-cf-marital-status' }
            break
          case '/employment-status/0':
            page_object = { title: 'Employment Status', fragment: 'input-cf-employment-status' }
            break
          case '/employment-duration/0':
            page_object = { title: 'Employment Duration', fragment: 'input-cf-employment-duration' }
            break
          case '/employment-title/0':
            page_object = { title: 'Job Title', fragment: 'input-cf-job-title' }
            break
          case '/employer-name/0':
            page_object = { title: 'Employer', fragment: 'input-cf-employer' }
            break
          case '/monthly-payment/0':
            page_object = { title: 'Monthly Income', fragment: 'input-cf-monthly-income' }
            break
          case '/form-finish':
            page_object = { title: 'Check Now', fragment: 'input-cf-check-now' }
            break
          default:
            page_object = undefined
            break
        }
        return page_object
    }
}