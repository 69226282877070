import FormStages from "@/components/form-stages.vue";
import ResultsStages from "@/components/results-stages.vue";
import { RouteRecordRaw } from "vue-router";

export const _routesForm: Array<RouteRecordRaw> = [
  {
    path: "apply",
    name: "apply",
    props: true,
    component: FormStages,
    children: [
      {
        path: "title",
        name: "title",
        props: true,
        component: () =>
          import(
            `@/components/stages/01-title.vue`
          ),
      },
      {
        path: "name",
        name: "name",
        props: true,
        component: () =>
          import(
            `@/components/stages/02-name.vue`
          ),
      },
      {
        path: "date-of-birth",
        name: "date-of-birth",
        props: true,
        component: () =>
          import(
            `@/components/stages/03-date-of-birth.vue`
          ),
      },
      {
        path: "email",
        name: "email",
        props: true,
        component: () =>
          import(
            `@/components/stages/04-email.vue`
          ),
      },
      {
        path: "phone",
        name: "phone",
        props: true,
        component: () =>
          import(
            `@/components/stages/05-phone.vue`
          ),
      },
      {
        path: "communication",
        name: "communication",
        props: true,
        component: () =>
          import(
            `@/components/stages/06-communication.vue`
          ),
      },
      {
        path: "licence-possesion",
        name: "licence-possesion",
        props: true,
        component: () =>
          import(
            `@/components/stages/07-licence-possesion.vue`
          ),
      },
      {
        path: "licence-type",
        name: "licence-type",
        props: true,
        component: () =>
          import(
            `@/components/stages/07.01-licence-type.vue`
          ),
      },
      {
        path: "vehicle-type",
        name: "vehicle-type",
        props: true,
        component: () =>
          import(
            `@/components/stages/08-vehicle-type.vue`
          ),
      },
      {
        path: "car-insurance",
        name: "carInsurance",
        props: true,
        component: () =>
          import(
            `@/components/stages/08.02-car-insurance.vue`
          ),
      },
      {
        path: "address-search/:index",
        name: "address-search",
        props: true,
        component: () =>
          import(
            `@/components/stages/09-address-search.vue`
          ),
      },
      {
        path: "address-manual/:index",
        name: "address-manual",
        props: true,
        component: () =>
          import(
            `@/components/stages/09.1-address-manual.vue`
          ),
      },
      {
        path: "address-search-results/:index",
        name: "address-search-results",
        props: true,
        component: () =>
          import(
            `@/components/stages/10-address-search-results.vue`
          ),
      },
      {
        path: "address-residential-status/:index",
        name: "address-residential-status",
        props: true,
        component: () =>
          import(
            `@/components/stages/11-address-residential-status.vue`
          ),
      },
      {
        path: "address-duration/:index",
        name: "address-duration",
        props: true,
        component: () =>
          import(
            `@/components/stages/11.1-address-duration.vue`
          ),
      },
      {
        path: "marital-status",
        name: "marital-status",
        props: true,
        component: () =>
          import(
            `@/components/stages/12-marital-status.vue`
          ),
      },
      {
        path: "employment-status/:index",
        name: "employment-status",
        props: true,
        component: () =>
          import(
            `@/components/stages/13-employment-status.vue`
          ),
      },
      {
        path: "employment-student-job/:index",
        name: "employment-student-job",
        props: true,
        component: () =>
          import(
            `@/components/stages/13.1-employment-student-job.vue`
          ),
      },
      {
        path: "employment-duration/:index",
        name: "employment-duration",
        props: true,
        component: () =>
          import(
            `@/components/stages/13.2-employment-duration.vue`
          ),
      },
      {
        path: "employment-title/:index",
        name: "employment-title",
        props: true,
        component: () =>
          import(
            `@/components/stages/14-employment-title.vue`
          ),
      },
      {
        path: "employer-name/:index",
        name: "employer-name",
        props: true,
        component: () =>
          import(
            `@/components/stages/15-employer-name.vue`
          ),
      },
      {
        path: "employer-town/:index",
        name: "employer-town",
        props: true,
        component: () =>
          import(
            `@/components/stages/16-employer-town.vue`
          ),
      },
      {
        path: "monthly-payment/:index",
        name: "monthly-payment",
        props: true,
        component: () =>
          import(
            `@/components/stages/17-monthly-payment.vue`
          ),
      },
      {
        path: "form-finish",
        name: "form-finish",
        props: true,
        component: () =>
          import(
            `@/components/stages/18-form-finish.vue`
          ),
      },
      {
        path: "date-check",
        name: "prepop-date",
        props: true,
        component: () =>
          import(
            `@/components/stages/21-prepop-date.vue`
          ),
      },
      {
        path: "review-details",
        name: "prepop-data",
        props: true,
        component: () =>
          import(
            `@/components/stages/22-prepop-full-data.vue`
          ),
      },
    ],
  },
];

export const _routesResults: Array<RouteRecordRaw> = [
  {
    path: "results",
    name: "results",
    props: true,
    component: ResultsStages,
    children: [
      {
        path: "form-results",
        name: "form-results",
        props: true,
        component: () =>
          import(
            `@/components/stages/19-form-results.vue`
          ),
      },
      {
        path: "form-apply",
        name: "form-apply",
        props: true,
        component: () =>
          import(
            `@/components/stages/20-form-apply.vue`
          ),
      },
      {
        path: "decline",
        name: "decline",
        props: true,
        meta: {
          requiresFormData: true
        },
        component: () =>
          import(
            `@/components/stages/19.5-form-decline.vue`
          ),
      }
    ],
  },
];
