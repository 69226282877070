
import LoanDetailsSetter from '@/components/loan-details-setter.vue'
import { defineComponent } from 'vue'
import { toCurrency } from '@ocean-ux/library/src/shared/utilities/formatters'

export default defineComponent({
  name: 'header-loan-details',
  computed: {
    progress: function (): number {
      return this.$store.getters.progress
    },
    loan_amount: function(): number {
      return this.$store.getters.formData.loanAmount;
    },
    loan_term: function(): string {
       return this.$store.getters.formData.loanTerm;
    }
  },
   components: {
    LoanDetailsSetter
  },
  data (): any {
      return {
          isSettingLoanDetailsOpen: false
      }
  },
  methods: {
    openSettingLoanDetails: function(): void {
      this.isSettingLoanDetailsOpen = true;
    },
    closeSettingLoanDetails: function(): void {
      this.isSettingLoanDetailsOpen = false;
    },
    formatCurrency: function (value: string): string {
      return value ? toCurrency(value) : ''
    }
  }
})
