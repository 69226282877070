import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles.scss'

import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App)
app.use(store)
app.use(router)

app.config.globalProperties.emitter = emitter

router.isReady().then(() => {
  app.mount("#app");
})
