import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_oux_form_layout = _resolveComponent("oux-form-layout")!
  const _component_loading_module = _resolveComponent("loading-module")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_oux_form_layout, {
      continueText: "Continue",
      submitText: "Get quote",
      submit: _ctx.submitForm,
      hideProgress: _ctx.hideProgressBar,
      hideNavigation: _ctx.hideNavigation,
      navigationBottom: false
    }, null, 8, ["submit", "hideProgress", "hideNavigation"]),
    (_ctx.isSubmitting)
      ? (_openBlock(), _createBlock(_component_loading_module, {
          key: 0,
          ready: _ctx.isSubmitted,
          percentageLoaded: _ctx.percentageLoaded
        }, null, 8, ["ready", "percentageLoaded"]))
      : _createCommentVNode("", true)
  ], 64))
}