
import { _analytics } from '@/utilities/analytics'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'form-holder',
  // eslint-disable-next-line
  data () {
    return {
      // transitionName variable is used to control animation class
      // the animation class will slide the page either left or right
      // depending on the next or previous page change event
      transitionName: '',
      // routeKey variable is used by the <router-view> component
      // :key is a required prop to make tranition work
      routeKey: ''
    }
  },
  components: {
  },
  mounted (): void {
    // set routeKey value
    this.routeKey = this.$route.path

    // get loan_amount and loan_term from URL parametors
    // e.g. /example-url/?loan_amount=2200&loan_term=24

    // if NOT on root url ("/")
    // which is the same as IS on the form
    // initialise analytics step funnel and time on step tracking
    // and set the next route
    if (this.$route.name !== "/") {
      // initialise analytics on mount
      _analytics.last_step_funnel(this.$route.path)
      _analytics.time_on_step(this.$route.path, this.$route.path)
    }
  },
  watch: {
    // watch for $route to change
    // eslint-disable-next-line
    '$route': function (router) {
      // if on the path "/" (index) dont try to set routes
      // app will automatically send user to first form step
      // and after this set routes
      if (router.name !== "/") {
        // set animation direction and router-view :key property var
        const routeDirection = router.query.d

        // set routeKey variable which sets <router-view/> 'route' item key
        // this is required for animation to work
        this.routeKey = router.path

        // set transition name
        this.setTransitionName(routeDirection)
        // set vuex 'nextRoute' variable whenever route changes
        this.setRouteValidity(routeDirection === 'f')

        // send analytics
        if (routeDirection === 'f') {
          _analytics.last_step_funnel(router.path)
          _analytics.time_on_step(this.prevRoute, router.path)
        }
      }
    }
  },
  computed: {
    // get the prevRoute variable from vuex store
    // used for analytics (step time)
    prevRoute: function (): string {
      return this.$store.getters.prevRoute
    },
  },
  methods: {
    setRouteValidity: function (directForward: boolean): void {
      // set vuex stepValid variable to true or false
      // based on the step movement direction
      // if moving backwards set stepValid to true otherwise set to false
      if (directForward) {
        this.$store.commit('setStepValid', false)
      } else {
        this.$store.commit('setStepValid', true)
      }
    },
    // eslint-disable-next-line
    setTransitionName: function (direction: string) {
      // based on direction set transitionName to slide left or right
      // this is used for page transition animation
      this.transitionName = direction !== 'f' ? 'slide-right' : 'slide-left'
    }
  }
})
