
import OceanLogo from '../assets/images/logo-primary.svg'

import { defineComponent } from 'vue'
export default defineComponent({
    name: 'app-header',
    data (): any {
        return {
            logo: OceanLogo,
            brand: process.env.VUE_APP_BRAND,
            href: process.env.VUE_APP_BRAND === 'ocean' ? 'https://www.oceanfinance.co.uk' : '/'
        }
    },
})
