import { createRouter, createWebHashHistory, RouteRecordRaw, RouteMeta } from "vue-router";

import Application from '@/views/Application.vue'
import Result from '@/views/Result.vue'
import store from '@/store';

import { _routesForm, _routesResults } from '@/utilities/_routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'application',
    component: Application,
    redirect: store.state.startRoute,
    children: _routesForm
  },
  {
    path: '/',
    name: 'results',
    component: Result,
    children: _routesResults
  },
  {
    path: '/:catchAll(.*)',
    redirect: store.state.startRoute
  }
]

const router = createRouter({
   history: createWebHashHistory(),
   routes
 });

 router.beforeEach(async (to, from, next) => {
  if (to.query.demo === 'true') {
    /* DEBUGGING */
    console.log(to.meta)
    await handleMockResults(to.meta);

    next();
  } else {
    /* If no router guards apply, proceed as normal */
    next();
  } 
});

async function handleMockResults(meta: RouteMeta) {
  /* Decline Screen Demo Data */
  if (meta.requiresFormData) {
    const { formData } = await import('../debugging/demo-formData');
    store.commit("prepopFormData", formData);
  }
}

export default router;
