import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid logo-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "header--message"
}
const _hoisted_6 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header py-2-5 text-center", [ _ctx.$slots.default ? 'added-height' : '' ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          href: _ctx.href,
          class: "col-lg-10 mx-auto"
        }, [
          _createElementVNode("img", {
            src: _ctx.logo,
            class: _normalizeClass(["logo d-block", {'ml-lg-0 ' : _ctx.$route.path.includes('results/form-results') }]),
            alt: _ctx.brand + ' logo'
          }, null, 10, _hoisted_4)
        ], 8, _hoisted_3)
      ])
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}