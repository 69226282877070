
import axios from "axios";
import { defineAsyncComponent, defineComponent } from "vue";

import AppHeader from '@/components/app-header.vue';
import LoadingSpinner from '@/components/UI/loading-circle.vue'
import { API } from "@/utilities/api_list";
import datalayers from '@/utilities/datalayers.js'
import HeaderLoanDetails from '@/components/header-loan-details.vue'
import { getCookie } from '@ocean-ux/library/src/shared/utilities/cookie-handling'

const Debug = defineAsyncComponent(
  () => import(/* webpackChunkName: `debugging-panel` */ "./debugging/debug.vue")
)

export default defineComponent({
  components: {
    AppHeader,
    LoadingSpinner,
    Debug,
    HeaderLoanDetails
  },
  data() {
    return {
      loadingUserData: true,
      brand: process.env.VUE_APP_BRAND
    }
  },
  computed: {
    showDebug: function (): boolean {
      const url = window.location.hostname;
      return url === "localhost" || url.includes("iltest");
    },
    isFirstStep: function (): boolean {
      return !this.$store.getters.isPrepop && this.$route.path.includes('apply/title');
    },
    isReviewDetails: function (): boolean {
      return this.$route.path.includes('apply/review-details');
    },
    isDebugging: function (): boolean {
      return (this.$store.getters.isDebugging || window.location.hostname === "localhost");
    }
  },
  async mounted(): Promise<void> {
    //adding default header for axios requests (62171: MDSec - CF Inconsistent Access Controls - No4)
    axios.defaults.headers.common['RequestVerificationToken'] = getCookie('XSRF-TOKEN')

    // sets the vh (view height) css variable
    this.setVHVariable()

    // get the expected and actual form start paths
    // this is used to check if the user is starting on correct form step
    const paths = this.getPaths()

    // if form is loaded not on the first step
    // and there is no customer_id for prepopulation
    // then send user to first step
    const doesContainPrepopToken = paths.actual.indexOf("?token") >= 0 || paths.actual.indexOf("?cftoken") >= 0;
    if (doesContainPrepopToken) {
      const token = paths.get_parameters["token"];
      const cftoken = paths.get_parameters["cftoken"];
      this.containsPrepopToken(token, cftoken)
      // if on the form starting stage
    } else if (!doesContainPrepopToken && paths.actual_stripped != paths.expected_stripped) {
      if (!this.isDebugging) {
        const navigationResult = await this.$router.push(paths.expected)
        if (!navigationResult) {
          this.loadingUserData = false
        }
      } else {
        this.loadingUserData = false
      }
    } else {
      this.loadingUserData = false
    }
    datalayers.fireQueuedDatalayers()
    this.setDataDogRum();
  },
  methods: {
    getPaths: function (): any {
      const expectedStartPath = this.$store.state.startRoute;
      const strippedExpectedStartPath = expectedStartPath.replace(/\//g, "")

      const after_question_mark = new RegExp(/\?(.*)/)

      const actual_start_path = window.location.hash // #/xyz/abc
      const stripped_actual_start_path = actual_start_path
        .replace("#", "")
        .replace(/\//g, "")
        .replace(after_question_mark, "")

      let getParams: any = {
        token: '',
        cftoken: ''
      }
      const splitedByQuestionMark = actual_start_path.split("?")
      if (splitedByQuestionMark.length > 1) {
        const getParameters = splitedByQuestionMark[1]
        getParams = getParameters.split("&").reduce(function (result: any, item: string) {
          const parts = item.split("=")
          result[parts[0]] = parts[1]
          return result
        }, {})
      }
      return {
        actual: actual_start_path,
        actual_stripped: stripped_actual_start_path,
        expected: expectedStartPath,
        expected_stripped: strippedExpectedStartPath,
        get_parameters: getParams
      }
    },
    containsPrepopToken: function (token: string, cftoken: string): void {
      //Check if contains prepop token to the form data
      if (token != undefined && token != '') {
        console.log('Found token param', token);
        const firstNameCheckUrl = API.firstNameCheckUrl;
        let name = '';
        axios.get(firstNameCheckUrl, { params: { token: token } }).then(response => {
          name = response.data.firstName;
        }).finally(() => {
          this.$store.commit("setPrepopFirstName", name)
          this.loadingUserData = false
          if (!name) {
            this.$router.push({ path: "/apply/title", query: { d: "f" } })
          }
        })
      } else {
        console.log('Found cftoken param', cftoken);
        const url = API.applyQuoteUrl;
        let username = '', applyLink = '', model = Object;
        axios.get(url, { params: { token: cftoken } }).then(response => {
          username = response.data.leadInfo.firstName
          applyLink = response.data.applyLink
          model = response.data
        }).finally(() => {
          this.$store.commit("setPrepopFirstName", username)
          this.$store.commit("setQuoteApplyInfo", model)
          this.loadingUserData = false
          if (!applyLink) {
            window.location.href = applyLink
          }
        })
      }
    },
    // sets the vh (view height) css variable
    // used to set app max-height
    setVHVariable: function (): void {
      // set vh css variable
      // this is used to set max page height
      // based on screen size (including any device UI etc)
      const vh = window.innerHeight * 0.01;
      // set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      // listen to the resize event
      window.addEventListener("resize", () => {
        // recalculate window height
        const vhi = window.innerHeight * 0.01;
        // // set the value in the --vhi custom property
        document.documentElement.style.setProperty("--vh", `${vhi}px`);
      });
    },
    setDataDogRum: function () {
      import(/* webpackChunkName: `datadog-browser-rum` */ '@datadog/browser-rum-slim').then((datadog) => {
        datadog.datadogRum.init({
          applicationId: '53ba19ad-8af9-49c3-955f-10bec00e146a',
          clientToken: 'pub5cb55d7a6d60548115d4848f6c99300e',
          site: 'datadoghq.eu',
          service: `${this.brand}-car-finance`,
          env: process.env.VUE_APP_ENV,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: process.env.VUE_APP_VERSION,
          sampleRate: 100,
          replaySampleRate: 0,
          trackInteractions: true
        });
      });
    }
  }
})
